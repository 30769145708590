<template>
    <div v-if="showButton" class="relative ">
        <button @click="installApp" class="logo-green-bg w-full">
            {{$t('install_app.banner')}}
        </button>
        <button @click="showButton = false" type="button"
            class=" absolute top-0 right-0 bg-white h-4 w-4 rounded-full mt-1 mr-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            data-v-2b2c4366=""><svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true" data-v-2b2c4366="">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"
                    data-v-2b2c4366=""></path>
            </svg></button>
    </div>
    <PWAPrompt v-if="iosInstallMessagePopUp" />
</template>

<script>
import { usePwaInstallPrompt } from '@d-gs/vue-pwa-install-prompt'
import MobileDetect from 'mobile-detect'
import { Capacitor } from '@capacitor/core';
import PWAPrompt from 'vue2-ios-pwa-prompt'


export default {
    name: 'pwaButton',
    components: {
        PWAPrompt
    },
    data() {
        return {
            pwaInstallPrompt: usePwaInstallPrompt(),
            showButton: false,
            iosInstallMessagePopUp:false,
        };
    },
    props: {
    },
    mounted() {
        if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches) {
			this.showButton = false;
		} else if(Capacitor.isNativePlatform()){
			this.showButton = false;
		}else{
            this.showButton = true;
        }
    },
    methods: {
        installApp(){
            const mobileDevice = new MobileDetect(navigator.userAgent)
            if(mobileDevice.userAgents().indexOf('Safari')!=-1){  // se il device è iOS allora mostro le istruzioni per installarlo
				this.iosInstallMessagePopUp=true
                this.$emit('showIOSPrompt')
			}
            this.showPrompt()
        },
        async showPrompt() {
            const outcome = await this.pwaInstallPrompt.actions.showPrompt()
            // outcome: 'accepted' | 'dismissed' | undefined
            if (outcome === 'accepted') {
                // Do something
                return
            }
            if (outcome === 'dismissed') {
                // Do something
                return
            }
        }
    }
}
</script>

<style>/* Aggiungi eventuali stili personalizzati qui */</style>