<template>
  <div>
    <div class="relative"> <!-- bottone di chiusura -->
			<button type="button" @click="closePopUp()" class=" absolute top-0 right-0 bg-white h-6 w-6 rounded-full mt-1 mr-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
				<svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</button>
		</div>
    <div class="flex p-4 bg-white rounded-lg shadow-xl place-items-center">
      <div class="text-left w-1/2">
        <h2 v-if="selectedPoint!=null" class="text-black font-bold text-lg">{{ selectedPoint.name }}</h2>
        <p v-if="selectedPoint!=null" class="text-gray-darker font-medium text-xs">{{ selectedPoint.address }}</p>
        <div v-if="selectedPoint ==null" class="h-2 bg-slate-200 rounded m-2 w-3/5"></div> <!-- cariamento -->
        <div v-if="selectedPoint ==null" class="h-2 bg-slate-200 rounded m-2 w-3/5"></div>
        <div v-if="selectedPoint ==null" class="h-2 bg-slate-200 rounded m-2 w-3/5"></div>
        <div v-if="selectedPoint ==null" class="h-2 bg-slate-200 rounded m-2 w-3/5"></div>
        <div v-if="selectedPoint ==null" class="h-2 bg-slate-200 rounded m-2 w-3/5"></div>
      </div>
      <div class="w-1/2 ">
        <button class="logo-green-bg float-right px-4 py-2 text-white rounded-full" @click="openMap()">{{ $t('openPopup.a') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'locationPopup',
    components: {
    },
    data() {
        return {
            locationInfo:null
        };
    },
    props: {
        selectedPoint: Object,
    },
    mounted(){
      //this.getAddressCoords("Cannaregio, 511, 30121 Venezia VE, Italia")
    },
    methods:{
      openMap() {  //apro mappa in una nuova pagina
        // per indirizzo = https://maps.google.com/?q=${this.locationInfo.address}
        // per coordinate = https://maps.google.com/?q=${this.lat},${this.lng}
        // `https://www.google.com/maps/dir/?api=1&destination=${this.selectedPoint.address}`
        this.registerRefillPointViewed(this.selectedPoint)
        const url = `https://www.google.com/maps/dir/?api=1&destination=${this.selectedPoint.lat},${this.selectedPoint.lng}`;
        window.open(url, '_blank');
      },
      closePopUp(){  //evento per chiudere popup, il listner é sulla home
        this.$emit('closePopUp');
      },
      registerRefillPointViewed(point){
        this.$axios.post(`${this.$root.apiDomain}/registerRefillPointViewed`, {
            id_point: point.id
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
        });
      }
    }
}
</script>