<template>
    <div>
        <div class="p-2 flex justify-center ">
            <video controls controlslist="nofullscreen" ref="video" autoplay="" loop="" muted="" playsinline class="mt-2 rounded-lg"
                src="https://clickandtwist.com/wp-content/uploads/2023/04/spritz-oclock-Apple-Devices-HD-Best-Quality.m4v"></video>
        </div>
    </div>
<s1/>
<s2/>

</template>
  
<script>
import s1 from './slider_1.vue'
import s2 from './slider_2.vue'
export default {
    name: 'descriptionPage',
    components:{
        s1,
        s2
    },
    data() {
        return {
        }
    },
    mounted(){
        this.setVideoHeight()
    },
    methods: {
        setVideoHeight(){
            this.$refs.video.style = `height: ${window.innerHeight*(0.75/3)}px;`  //7/12
        },
    },

}
</script>
  
<style>.top_notch {
    padding-top: calc(constant(safe-area-inset-top) - 8px);
    padding-top: calc(env(safe-area-inset-top) - 8px);
    background-color: black;
}

.top_notch_space {
    padding-top: calc(constant(safe-area-inset-top) + 3rem);
    padding-top: calc(env(safe-area-inset-top) + 3rem);
    background-color: white;
}

.card-background {
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
}

.card {
    background-image: url('../../assets/PATTERN-BICCHIERI.png');
}

</style>