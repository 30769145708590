<template>
    <div class="p-2">
        <div class="flex justify-center items-center w-full z-20 ">
            <form class="bg-white rounded-lg w-full" @submit.prevent>
                <h1 class="text-2xl font-bold mb-4">{{$t('accountPage.signUp.desc.a')}}</h1>
                <div>
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="username">
                            {{$t('accountPage.signUp.desc.b')}}
                        </label>
                        <input v-model="username"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email" type="username" :placeholder="$t('accountPage.signUp.desc.c')" required />
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="email">
                            {{$t('accountPage.signUp.desc.e')}}
                        </label>
                        <input v-model="email"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email" type="email" :placeholder="$t('accountPage.signUp.desc.f')" required />
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="password">
                            {{$t('accountPage.signUp.desc.g')}}
                        </label>
                        <input v-model="password1"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password" type="password" :placeholder="$t('accountPage.signUp.desc.h')" required />
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 font-bold mb-2" for="password">
                            {{$t('accountPage.signUp.desc.i')}}
                        </label>
                        <input v-model="password2" @input="passwordValidation()"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password" type="password" :placeholder="$t('accountPage.signUp.desc.h')" required />
                    </div>

                    <div class="flex items-center">
                        <input type="checkbox" id="marketing-emails" name="marketing-emails"
                            class="h-5 w-5 text-green-500 border-gray-300 rounded focus:ring-green-500"
                            v-model="marketingEmails" :true-value="true" :false-value="false">
                        <label for="marketing-emails" class="ml-2 block text-sm text-gray-900">
                            {{$t('accountPage.signUp.desc.j')}}
                        </label>
                    </div>

                    <div class="flex items-center mt-2">
                        <input type="checkbox" id="privacy" name="privacy"
                            class=" text-green-500 border-gray-300 rounded focus:ring-green-500 h-5 w-5" :true-value="true"
                            :false-value="false" required>
                        <label for="" class="ml-2 block text-sm text-left text-gray-900 w-fit">
                            {{$t('accountPage.signUp.desc.k')}} <span @click="open('/privacy')" class="text-lime-500">
                                Privacy policy </span>
                        </label>
                    </div>

                    <div v-if="password1 != password2 && password1 != '' && password2 != ''"
                        class="bg-red-400 text-white font-bold py-2 px-4 my-2 rounded "> {{$t('accountPage.signUp.desc.l')}}
                    </div>
                    <div v-if="emailAlreadyUse" class="bg-red-400 text-white font-bold py-2 px-4 my-2 rounded "> {{$t('accountPage.signUp.desc.m')}}
                    </div>
                    <button @click="signinWithEmail()"
                        class="logo-green-bg hover:bg-lime-600 text-white font-bold py-2 px-4 my-2 rounded w-full">{{$t('accountPage.signUp.button.a')}}</button>
                    <button @click="cancel()"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full">{{$t('accountPage.signUp.button.b')}}</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
const sha256 = require('js-sha256');

export default {
    name: 'signupWithEmail',
    data() {
        return {
            loggedIn: false,
            username: '',
            email: '',
            password1: '',
            password2: '',
            passwordEquals: false,
            country: '',
            marketingEmails: false,
            emailAlreadyUse: false,
        }
    },
    mounted() {
        this.getCountryInfo()
    },
    methods: {
        signinWithEmail() {
            if (this.password1 != this.password2 || this.password1 === '' || this.password2 === '' || this.email === '' || this.username === '') { return }
            let passwordHash = sha256(this.password1);
            this.$axios
                .post(`${this.$root.apiDomain}/registerNewUser`, {
                    signupwith: "email",
                    email: this.email,
                    password_hash: passwordHash,
                    username: this.username,
                    country: this.country,
                    allowEdvertisingEmail: this.marketingEmails,
                })
                .then(async (response) => {
                    await localStorage.setItem('sessionId', response.data.id)
                    this.$emit('userLogged')
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status == 409) {
                        this.emailAlreadyUse = true
                        let t = this
                        setTimeout(() => {
                            t.emailAlreadyUse = false
                        }, 5000)
                    }
                })
        },
        getCountryInfo() {
            this.$axios
                .get("https://freeipapi.com/api/json")
                .then((response) => {
                    this.country = response.data.countryName
                })
                .catch((error) => {
                    console.log(error);

                })
        },
        cancel() {
            this.$emit("close")
        },
        open(url) {
            window.open(url, '_blank');
        },
    },
}
</script>
  
<style>
/* Use TailwindCSS classes here as needed */</style>