export default{
    bottombar:{
        a:'REFILL',
        b:'HOW DOES IT WORK',
        c:'ACCOUNT'
    },
    research:{
        research:'Research...'
    },
    install_app:{
        banner:'Click to install the app!',
        iosPopUp:{
            a:'To install this application on your iOS device:',
            b:'Tap the "Share" button in the bar inferior',
            c:'Choose "Add to Home Screen"'
        }
    },
    openPopup:{
        a:'Go to the place'
    },
    descPage:{
        cardA:{
            title:'Flip',
            desc:'the tab 180°'
        },
        cardB:{
            title:'Click the valve',
            desc:'and wait for the excess pressure to subside'
        },
        cardC:{
            title:'Twist the cap',
            desc:'and enjoy your spritz!'
        }
    },
    accountPage:{
        loginPage:{
            desc:{
                a:'Log in',
            },
            buttons:{
                a:'Log in with Google',
                b:'Log in with Email',
                c:'Sign up with Email'
            }
        },
        logInPage:{
            desc:{
                a:'Log In',
                b:'Email',
                c:'Enter your email address',
                d:'Password',
                e:'Enter your password',
                f:'Wrong username or password'
            },
            buttons:{
                a:'Log In',
                b:'Cancel',
                c:'Reset password'
            }
        },
        signUp:{
            desc:{
                a:'Sign up',
                b:'Username',
                c:'Enter you username',
                d:'Log In',
                e:'Email',
                f:'Enter your email address',
                g:'Password',
                h:'Enter your password',
                i:'Repeat password',
                j:'I agree to receive marketing emails.',
                k:'AI hereby authorize the use of my personal data in accordance to the GDPR 679/16 “European regulation” and Italian law 196/03.',
                l:'mismatched passwords',
                m:'Email already use'
            },
            button:{
                a:'Log In',
                b:'Cancel',
            }
        },
        googleSignUp:{
            desc:{
                a:'Do you want to receive marketing emails?',
                b:'I agree to receive marketing emails.',
                c:'AI hereby authorize the use of my personal data in accordance to the GDPR 679/16 “European regulation” and Italian law 196/03.'
            },
            button:{
                a:'Continue',
                b:'Cancel',
            }
        },
        main:{
            desc:{
                a:'Country',
                b:'I would like to receive marketing and advertising emails'
            },
            buttons:{
                a:'Edit profile',
                b:'Logout'
            }
        },
        edit:{
            desc:{
                a:'Country',
                b:'Username'
            },
            button:{
                a:'Change password',
                b:'Delete Account',
                c:'Update',
                d:'Cancel',
            }
        },
        resetPassword:{
            desc:{
                a:'Reset password',
                b:'Email',
                c:'OTP code',
                d: 'mismatched passwords',
                e: 'Bad OTP code'
            },
            button:{
                a:'Send me OTP code',
                b:'Reset password',
                c: 'Cancel'
            }
        },
        delete:{
            desc:{
                a:'Are you sure you want to delete your account?',
                b:'All data will be lost',
            },
            button:{
                a:'Delete',
                b:'Cancel'
            }  
        }
    },
    ageAllert:{
        desc:{
            aa:'You are currently in',
            ab:', to use alcohol you must be of age.'
        },
        button:{
            a:'I confirm that I am of legal age'
        }
    },
    gpsPermission:{
        desc:{
            a:'We need your location to find refill points near you!',
            b:'If the problem persists:',
            c:'Check that you have activated GPS',
            d:'Check that you have authorized the browser to access the GPS',
            e:'Change browser'
        },
        button:{
            a:'Request Authorization'
        }
    }
}