<template>
    <div>
        <div class="relative overflow-auto" ref="component">
    <div ref="cards" class="flex flex-nowrap py-4 px-8 ">
        <div ref="card0" class="flex-none w-fit rounded-lg shadow-xl border border-gray-200 "
            @click="scrollToImage(0)">
            <img ref="img" src="../../assets/flip-photo.png" class="h-full  d-block " alt="">
        </div>
        <div ref="card1" class="flex-none w-fit rounded-lg shadow-xl border border-gray-200 "
            @click="scrollToImage(1)">
            <img ref="img" src="../../assets/click-photo.png" class="h-full  d-block " alt="">
        </div>
        <div ref="card2" class="flex-none w-fit rounded-lg shadow-xl border border-gray-200 "
            @click="scrollToImage(2)">
            <img ref="img" src="../../assets/twist-photo.png" class=" h-full  d-block " alt="">
        </div>
        <div class="mx-2 p-2"></div>
    </div>
</div>
        <div class="flex justify-center mt-2">
            <button class="rounded-full border font-bold mx-2 px-4 py-2 focus:outline-none"
                :class="selected == 0 ? 'bg-black text-white' : 'border-black text-black '"
                @click="scrollToImage(0)">1</button>
            <button class="rounded-full border font-bold mx-2 px-4 py-2 focus:outline-none"
                :class="selected == 1 ? 'bg-black text-white' : 'border-black text-black '"
                @click="scrollToImage(1)">2</button>
            <button class="rounded-full border font-bold mx-2 px-4 py-2 focus:outline-none"
                :class="selected == 2 ? 'bg-black text-white' : 'border-black text-black '"
                @click="scrollToImage(2)">3</button>
        </div>
    </div>
</template>
  
  
<script>
export default {
    name: 'descriptionPage',
    data() {
        return {
            selected: 0,
            isMoving:false,
            cardPosition:[
                {
                    num:0,
                    pos:0
                },                
                {
                    num:1,
                    pos:0
                },                
                {
                    num:2,
                    pos:0
                },
            ],
        }
    },
    mounted(){
        setInterval(()=>{
            try {
                this.checkPositionCard()
            } catch (error) {
              null  
            }
        }, 100)
        this.setCardsHeight()
        this.setCardsWidth()
    },
    methods: {
        scrollToImage(index) {
            this.selected = index
            this.isMoving = true
            this.$refs[`card${index}`].scrollIntoView({ behavior: 'smooth', block: "center", inline: "center"})
            setTimeout(() => {
                this.isMoving = false
            }, 500)
        },
        checkPositionCard(){
            if(this.isMoving){return}
            let lowerCard = {ncard:0,xValue:100000};
            for (let index = 0; index < this.cardPosition.length; index++) {
                this.cardPosition[index].pos = this.$refs[`card${index}`].getBoundingClientRect().x
                if(this.cardPosition[index].pos < 0){this.cardPosition[index].pos = this.cardPosition[index].pos * -1 } // rendo il numero sempre positivo per capicre quale carta è più vicina allo 0 
                if (this.cardPosition[index].pos < lowerCard.xValue ) {
                    lowerCard.xValue = this.cardPosition[index].pos
                    lowerCard.ncard = index
                }
            }
            this.selected = lowerCard.ncard
        },
        setCardsHeight(){
            this.$refs.cards.style = `height: ${(window.innerHeight-this.$root.topMargin)*(3/6)}px;`
        },
        setCardsWidth(){
            for (let index = 0; index < this.cardPosition.length; index++) {
                let m = ((window.innerWidth-this.$refs[`card${index}`].clientWidth)-40)/7 //7
                this.$refs[`card${index}`].style = `margin: 0px ${m}px 0px; `
            }
        },
    },

}
</script>
  
<style>.top_notch {
    padding-top: calc(constant(safe-area-inset-top) - 8px);
    padding-top: calc(env(safe-area-inset-top) - 8px);
    background-color: black;
}

.top_notch_space {
    padding-top: calc(constant(safe-area-inset-top) + 3rem);
    padding-top: calc(env(safe-area-inset-top) + 3rem);
    background-color: white;
}

.card-background {
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
}

.card {
    background-image: url('../../assets/PATTERN-BICCHIERI.png');
}

</style>