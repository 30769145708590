<template>
    <div class=" flex flex-col justify-center  sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {{$t('accountPage.resetPassword.desc.a')}}
            </h2>
        </div>

        <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-4 px-4  sm:rounded-lg sm:px-10">
                <form class="space-y-6" @submit.prevent="">
                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">
                            {{$t('accountPage.resetPassword.desc.b')}}
                        </label>
                        <div class="mt-1">
                            <input id="email" name="email" type="email" autocomplete="email" required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                                v-model="email" />
                        </div>
                    </div>

                    <div v-if="otpCodeRequred">
                        <label for="OTPCode" class="block text-sm font-medium text-gray-700">
                            {{$t('accountPage.resetPassword.desc.c')}}
                        </label>
                        <div class="mt-1">
                            <input id="OTPCode" name="OTPCode" required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                                v-model="OTPCode" />
                        </div>
                    </div>

                    <div v-if="otpCodeRequred">
                        <label for="password" class="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <div class="mt-1">
                            <input id="password" name="password" type="password"  required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                                v-model="password" />
                        </div>
                        <label for="password2" class="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <div class="mt-1">
                            <input id="password2" name="password2" type="password"  required
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
                                v-model="password2" />
                        </div>
                    </div>

                    <div v-if="password != password2 && password != '' && password2 != ''"
                        class="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 my-2 rounded ">{{$t('accountPage.resetPassword.desc.d')}}
                    </div>

                    <div v-if="resetError">
                        <div 
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400">
                            {{$t('accountPage.resetPassword.desc.e')}}
                        </div>
                    </div>

                    <div v-if="!otpCodeRequred" @click="requestOTP">
                        <button type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white logo-green-bg hover:bg-lime-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">
                            {{$t('accountPage.resetPassword.button.a')}}
                        </button>
                    </div>

                    <div v-if="otpCodeRequred">
                        <button @click="sendNewPassword"
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white logo-green-bg hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">
                            {{$t('accountPage.resetPassword.button.b')}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="absolute inset-x-0 bottom-16 p-2">
            <button class="mt-2 py-2 px-4 rounded-md shadow-md bg-red-500 text-white w-full" @click="close">{{$t('accountPage.resetPassword.button.c')}}</button>
        </div>
    </div>
</template>
  
<script>
const sha256 = require('js-sha256');

export default {
    name: "resetPassword",
    data() {
        return {
            email: '',
            password: '',
            password2:'',
            OTPCode:'',
            otpCodeRequred:false,
            loginError : false,
        }
    },
    methods: {
        sendNewPassword() {
            let passwordHash = sha256(this.password);
            this.$axios
                .post(`${this.$root.apiDomain}/userPasswordReset/setNewPassword`, 
                {
                    email:this.email, 
                    OTPcode:this.OTPCode,
                    newPasswordHash:passwordHash
                })
                .then(() => {
                    this.$emit("close")
                })
                .catch((error) => {
                    if(error.response.status === 401){
                        this.resetError = true
                    }
                    console.log(error);
                })
        },
        requestOTP() {
            this.$axios
                .post(`${this.$root.apiDomain}/userPasswordReset/requestNewOTP`, 
                {
                    email:this.email, 
                })
                .then(() => {
                    this.otpCodeRequred = true;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        close() {
            this.$emit("close")
        }
    }
}
</script>