<template>
  <div class="fixed top_notch z-10">
    <header class="flex items-center  w-screen"> <!-- justify-between -->
      <div class="w-full h-full flex justify-between">
        <div class="bg-gray-processed w-1/2 flex items-center justify-between">
          <img src="../src/assets/logo-en.png" class="h-10 m-2" @click="openUrl('https://liquidnation.it')"> <!-- '../src/assets/logo-en.png' 'https://liquidnation.it' -->
          <div class="logo-red-text font-bold text-2xl ">
            REF
          </div>
        </div>
        <div class="bg-gray-processed w-1/2 flex items-center justify-between">
          <div class="logo-green-text font-bold text-2xl ">
            ILL
          </div>
          <img src="../src/assets/LOGO-CLICK-AND-TWIST-2.png" class="h-10 m-2"
            @click="openUrl('https://clickandtwist.com')">
        </div>
      </div>
    </header>
    <pwaInstall @showIOSPrompt="showIOSPWAprompt=true" class="z-10" />
  </div>
  <router-view :tempid="tempid"></router-view>
  <pwaIOSPrompt v-if="showIOSPWAprompt" @close="showIOSPWAprompt=false" class="z-20  my-1 rounded bottom-0 fixed left-0 "/>
</template>

<script>
//import homePage from './components/home_page.vue';
import { Capacitor } from '@capacitor/core';
import { usePwaInstallPrompt } from '@d-gs/vue-pwa-install-prompt'
import pwaInstall from './components/pwa_install.vue'
import pwaIOSPrompt from './components/pwaIosPrompt.vue'
//vue2-ios-pwa-prompt


export default {
  name: 'App',
  components: {
    pwaInstall,
    pwaIOSPrompt
  },
  data() {
    return {
      isNativeApp: false,
      apiDomain: "https://api.glassrefill.com",
      nativeoAuthGoogleUrl: 'https://www.glassrefill.com/google-oauth-bridge',
      showIOSPWAprompt:false,
      topMargin : getComputedStyle(document.documentElement).getPropertyValue("--sat")!='0px'?(window.innerHeight*0.08):0
    }
  },
  mounted() {
    if (Capacitor.isNativePlatform()) {
      this.isNativeApp = true;
    }
    const pwaInstallPrompt = usePwaInstallPrompt()

    // Service Worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js')
        .then((reg) => {
          reg.onupdatefound = () => {
            reg.update()
          }
        })

      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault()
        pwaInstallPrompt.actions.setInstallPromptEvent(event)
      })
    }

  },
  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style>
.div_app {
  padding-top: calc(constant(safe-area-inset-top));
  padding-top: calc(env(safe-area-inset-top));
  background-color: black;
}

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}
</style>
