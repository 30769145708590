<template>
    <div v-if="updateUserActive" class="m-2">
        <updateUser ref="updateUserRef" @close="updateUserActive=false" @updated="updateUserActive=false,getUserInfo()" @accountDeleted="logOut()"/>
    </div>
    <div v-if="!updateUserActive" class="m-2">
        <div class="flex justify-center items-center w-full">
            <div class="px-4 pt-4 rounded-md bg-white w-full">
                <div class="grid grid-flow-row-dense grid-cols-3 items-center">
                    <img :src="userAvatar"
                        class="w-24 h-24 rounded-full mr-8">
                    <div class="col-span-2 items-center">
                        <h2 class="text-2xl font-bold">{{ username }}</h2>
                        <p class="text-xs text-gray-500">{{ email }}</p>
                    </div>
                </div>
                <div class="flex items-center justify-between mt-5">
                    <p class="text-xs text-gray-500">{{$t('accountPage.main.desc.a')}}: {{ userCountry }}</p>
                    <img :src="countryImage" class="w-5 h-5 rounded-full mr-2">
                </div>
                <div class="flex items-center justify-between mt-5">
                    <p class="text-xs text-gray-500 text-left">{{$t('accountPage.main.desc.b')}}</p>
                    <label for="default-toggle" class="inline-flex relative cursor-pointer"> <!-- switch on of notifiche -->
                        <input type="checkbox" :checked="allow_adv_email" v-model="value" value="" id="default-toggle"
                            class="sr-only peer" @change="changeMarketingEmailsPreferences(value)">
                        <div
                            class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-lime-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-lime-500">
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <button class="mt-8 py-2 px-4 rounded-md shadow-md logo-green-bg text-white w-full " @click="showModifyUser()">
            <div class="inline-flex items-center text-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="fill-white mr-2" height="20" viewBox="0 96 960 960"
                    width="20">
                    <path
                        d="M180 876h44l443-443-44-44-443 443v44Zm614-486L666 262l42-42q17-17 42-17t42 17l44 44q17 17 17 42t-17 42l-42 42Zm-42 42L248 936H120V808l504-504 128 128Zm-107-21-22-22 44 44-22-22Z" />
                </svg>
                {{$t('accountPage.main.buttons.a')}}
            </div>
        </button>
        <div class="absolute inset-x-0 bottom-16 p-2 pb-4">
            <button class="mt-2 py-2 px-4 rounded-md shadow-md bg-red-500 text-white w-full" @click="logOut">{{$t('accountPage.main.buttons.b')}}</button>
        </div>
    </div>
</template>
<script>
import updateUser from './edit_user.vue'
export default {
    name: 'accountPage',
    data() {
        return {
            updateUserActive: false,
            username: '',
            email: '',
            allow_adv_email: false,
            userCountry: '',
            signupWith: '',
            countryImage: '',
            userAvatar: 'https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg',
        }
    },
    components: {
        updateUser
    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        logOut() {
            localStorage.removeItem('sessionId')
            this.$emit("logout")
        },
        getUserInfo() {
            this.$axios.post(`${this.$root.apiDomain}/getUserInfo/session`,
                {
                    sessionId: localStorage.getItem('sessionId')
                })
                .then((response) => {
                    this.username = response.data.username,
                    this.email = response.data.email,
                    this.allow_adv_email = response.data.allow_advertising_email
                    this.userCountry = response.data.country
                    this.signupWith = response.data.signup_with
                    if(response.data.profile_image!=null){
                        this.userAvatar = response.data.profile_image
                    }
                    this.getFlagsImage()
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        getFlagsImage() {
            this.$axios.get(`https://restcountries.com/v2/name/${this.userCountry}?fullText=true`)
                .then((response) => {
                    this.countryImage = response.data[0].flags.png
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        changeMarketingEmailsPreferences(newValue) {
            this.$axios.post(`${this.$root.apiDomain}/updateAdvPreferences`,
                {
                    sessionId: localStorage.getItem('sessionId'),
                    newpermission: newValue
                })
                .then((response) => {
                    this.allow_adv_email = response.data.adv_permission
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        showModifyUser(){
            this.updateUserActive = true
            setTimeout(()=> {
                this.$refs.updateUserRef.setUserInfo({
                    username: this.username,
                    email: this.email,
                    country: this.userCountry,
                    userAvatar: this.userAvatar,
                    signupWith : this.signupWith
                })
            }, 50)
        }
    },
}
</script>
  
<style>
/* Use TailwindCSS classes here as needed */
</style>