import en from './en'
import it from './it'
import { createI18n } from 'vue-i18n'


const messages = {
    it:it,
    en:en
}

const i18n = createI18n({
    locale: navigator.language, // Imposta la lingua del dispositivo come lingua di default
    fallbackLocale: 'en',
    messages  
  })

export default i18n