<template>
    <div v-if="isNewUser" class="m-2 mt-4">
        <h1 class="text-xl font-bold mb-4">{{$t('accountPage.googleSignUp.desc.a')}}</h1>
        <div class="flex items-center">
            <input type="checkbox" id="marketing-emails" name="marketing-emails"
                class="h-5 w-5 text-green-500 border-gray-300 rounded focus:ring-green-500" v-model="marketingEmails"
                :true-value="true" :false-value="false" required>
            <label for="marketing-emails" class="ml-2 block text-sm text-gray-900">
                {{$t('accountPage.googleSignUp.desc.b')}}
            </label>


        </div>
        <form @submit.prevent>
                <div class="flex items-center mt-2">
                    <input type="checkbox" id="privacy" name="privacy" v-model="privacyPolicyAccepted"
                        class="  text-green-500 border-gray-300 rounded focus:ring-green-500 h-5 w-5" :true-value="true"
                        :false-value="false" required>
                    <label for="" class="ml-2 block text-sm text-left text-gray-900 w-fit">
                        {{$t('accountPage.googleSignUp.desc.c')}} <span @click="open('/privacy')" class="text-lime-500"> Privacy
                            policy </span>
                    </label>
                </div>
                <button @click="signupWithGoogle()"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-2 rounded w-full">{{$t('accountPage.googleSignUp.button.a')}}</button>
            </form>
        <button @click="cancel()"
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full">{{$t('accountPage.googleSignUp.button.b')}}</button>
    </div>
</template>
<script>
import { googleTokenLogin } from "vue3-google-login";
import { Browser } from '@capacitor/browser';
import { uuid } from 'vue-uuid';

export default {
    name: 'loginWithGoogle',
    data() {
        return {
            googleToken: '',
            isNewUser: false,
            marketingEmails: false,
            country: '',
            privacyPolicyAccepted:false
        }
    },
    mounted() {
        this.loginWithGoogle()
        this.getCountryInfo()
    },
    methods: {
        async loginWithGoogle() {
            if(this.$root.isNativeApp ){  //|| !this.$root.isNativeApp
                await this.nativeoAuthGoogle()
            }else{
                this.googleToken = await googleTokenLogin()
                this.loginEngine()
            }
        },
        async loginEngine(){
            this.$axios
                .post(`${this.$root.apiDomain}/userAutentication`,
                    {
                        signinwith: this.$root.isNativeApp?"googlewithbridge":"google",
                        google_access_token: this.googleToken.access_token,
                        bridgetempid:this.$root.isNativeApp?this.bridgetempid:null
                    })
                .then(async (response) => {
                    await localStorage.setItem('sessionId', response.data.id)
                    this.$emit('userLogged')
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.isNewUser = true;
                    }
                    console.log(error);
                })
        },
        async signupWithGoogle() {
            if(!this.privacyPolicyAccepted){return}
            this.$axios
                .post(`${this.$root.apiDomain}/registerNewUser`,
                    {
                        signinwith: this.$root.isNativeApp?"googlewithbridge":"google",
                        bridgetempid:this.$root.isNativeApp?this.bridgetempid:null,
                        google_access_token: this.googleToken.access_token,
                        country: this.country,
                        allowEdvertisingEmail: this.marketingEmails,
                    })
                .then(async (response) => {
                    await localStorage.setItem('sessionId', response.data.id)
                    this.$emit('userLogged')
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        cancel() {
            this.$emit("close")
        },
        getCountryInfo() {
            this.$axios
                .get("https://freeipapi.com/api/json")
                .then((response) => {
                    this.country = response.data.countryName
                })
                .catch((error) => {
                    console.log(error);

                })
        },
        open(url) {
            window.open(url, '_blank');
        },
        async nativeoAuthGoogle(){
            this.bridgetempid = uuid.v4();
            const url = `${this.$root.nativeoAuthGoogleUrl}?tempid=${this.bridgetempid }`;
            await Browser.open({ url });
            Browser.addListener('browserFinished', this.loginEngine) 
        }
    },
}
</script>
  
<style>
/* Use TailwindCSS classes here as needed */</style>