<template>
<div class="relative overflow-auto" ref="component">
    <div class="flex flex-nowrap h-full w-fit">
        <div v-for="singleNews in news" :key="singleNews" class="flex-none mx-2">  
            <div class="flex bg-white rounded-lg shadow-xl p-2 h-full flex-none border border-gray-200" @click="open(singleNews.link)">
                <img :src="getImageUrl(singleNews.image)" alt="Image" class="rounded-lg h-full">
                <div class="w-min place-self-center ml-2">
                    <div class="px-2 place-self-center">
                        <h2 class="text-xl font-bold truncate">{{ singleNews.title }}</h2>
                        <div class="border-t-2 border-gray-200"></div>
                        <p class="text-gray-700 overflow-hidden leading-tight max-h-16 text-ellipsis mt-2">{{ singleNews.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
    name: 'viewArticle',
    components: {
    },
    data() {
        return {
            news:[]
        };
    },
    props: {
    },
    mounted(){
        this.getCountryInfoForNews()
    },
    methods:{
        setComponentHeight(){
            let topmargin = 0;
            if(this.$root.isNativeApp){
                topmargin = this.$root.topMargin;
            }
            this.$refs.component.style = `height: ${(window.innerHeight-topmargin)*(1/6)}px;`
        },
        open(url){
            if (!url.startsWith('http')){
                url = `https://${url}`
            }
            window.open(url, '_blank');
        },
        getCountryInfoForNews() {
            this.$axios
            .get("https://freeipapi.com/api/json") 
            .then((response) => {
                this.countryInfo = response.data.countryName
                this.getNews()
            })
            .catch((error) => {
                console.log(error); 
            })
        },
        getNews(){
            this.$axios
            .get(`${this.$root.apiDomain}/getNews/byCountry?country=${this.countryInfo}`) 
            .then((response) => {
                this.news = response.data
                this.setComponentHeight()
            })
            .catch((error) => {
                console.log(error); 
            })
        },
        getImageUrl(imageName){
            return `${this.$root.apiDomain}/newsImage/${imageName}`
        },
    }
}
</script>

<style scoped>
/* Aggiungi eventuali stili personalizzati qui */
</style>