<template>
    <div class=" mx-4">
        <div class="h-16"></div>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Spritz o&rsquo;clock</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">ClickandTwist</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Via Rialto 1&nbsp;</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Martellago, 30030, VE, Italy</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><a class="text-lime-500"
                    href="mailto:contacts@clickandtwist.com">contacts@clickandtwist.com</a></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><a class="text-red-500"
                    href="mailto:info@liquidnation.it">info@liquidnation.it</a></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong><span style="font-size: 28px;">Privacy
                        Policy</span></strong></span></p>
        <p><span style="font-family: Tahoma, Geneva, sans-serif; color: rgb(0, 0, 0);">Last Updated: 05/05/2023</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">At ClickandTwist, we take your privacy seriously. This
                Privacy Policy describes how we collect, use,</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">and protect your personal data in compliance with the
                European General Data Protection Regulation</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">(GDPR). By using our app, you acknowledge and agree to the
                terms of this Privacy Policy.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>1. Data Collection</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We collect both anonymous and non-anonymous data from our
                users. The types of data we collect</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">are as follows:</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Anonymous Data:</span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Refilling point visited</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Approximate location</span></li>
        </ul>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Non-Anonymous Data:</span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Name</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Surname</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Email address</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Nationality</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Login method (email or Google login)</span></li>
        </ul>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>2. Purpose of Data Collection</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We collect and process your data for the following
                purposes:</span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To provide, maintain, and improve our app and its
                    services</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To communicate with you about our services and
                    promotions</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To personalize and enhance your user experience</span>
            </li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To monitor and analyze usage and trends</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To enforce our terms of service and protect against
                    fraud, abuse, and other unlawful activities</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To comply with legal obligations and respond to
                    requests from authorities</span></li>
        </ul>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>3. Legal Basis for Data Processing</strong></span>
        </p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We process your personal data based on the following legal
                grounds:</span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Your consent, which you can withdraw at any
                    time</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">The performance of a contract or to take steps at your
                    request before entering into a contract</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Our legitimate interests, such as providing and
                    improving our services and ensuring the security of</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">our app</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Compliance with legal obligations</span></li>
        </ul>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>4. Data Retention</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We retain your personal data only for as long as necessary
                to fulfill the purposes for which it was</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">collected, including legal, accounting, and reporting
                requirements. Anonymous data may be stored</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">indefinitely.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>5. Data Sharing</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We do not sell, rent, or share your personal data with
                third parties, except in the following</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">circumstances:</span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">With service providers who assist us in operating and
                    maintaining our app, provided they have&nbsp;adequate safeguards in place to protect your data</span>
            </li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">When required by law, regulation, legal process, or
                    government request</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">To protect the rights, property, or safety of
                    ClickandTwist, our users, or the public</span></li>
        </ul>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>6. Data Security</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">We have implemented appropriate technical and
                organizational measures to protect your personal</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">data from unauthorized access, alteration, disclosure, or
                destruction. However, no method of</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">transmission or storage is completely secure, and we cannot
                guarantee the absolute security of your</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">data.</span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>7. Your Rights</strong></span></p>
        <p><span style="font-family: Tahoma,Geneva, sans-serif;">Under the GDPR, you have the right to:</span></p>
        <ul>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Access your personal data</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Rectify inaccurate or incomplete data</span></li>
            <li><span style="font-family: Tahoma,Geneva, sans-serif;">Erase your personal data, subject to certain
                    exceptions</span></li>
        <li><span style="font-family: Tahoma,Geneva, sans-serif;">Restrict the processing of your data</span></li>
        <li><span style="font-family: Tahoma,Geneva, sans-serif;">Object to the processing of your data</span></li>
        <li><span style="font-family: Tahoma,Geneva, sans-serif;">Receive your data in a portable format or have it
                transmitted to another controller</span></li>
    </ul>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">To exercise these rights or withdraw your consent, please
            contact us at:</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><a class="text-lime-500"
                href="mailto:contacts@clickandtwist.com">contacts@clickandtwist.com</a></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><a class="text-red-500"
                href="mailto:info@liquidnation.it">info@liquidnation.it</a></span></p>
    <p><br></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>8. Changes to This Privacy Policy</strong></span>
    </p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">We may update this Privacy Policy from time to time. We
            will notify you of any significant changes</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">by posting the new Privacy Policy on our app or by sending
            you an email. Your continued use of the</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">app after any changes constitutes your acceptance of the
            updated Privacy Policy.</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><strong>9. Contact Us</strong></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">If you have any questions or concerns about this Privacy
            Policy or our data practices, please contact us at:</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">Spritz o&rsquo;clock</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">ClickandTwist</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">Via Rialto 1&nbsp;</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">Martellago, 30030, VE, Italy</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><a class="text-lime-500"
                href="mailto:contacts@clickandtwist.com">contacts@clickandtwist.com</a></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><a class="text-red-500"
                href="mailto:info@liquidnation.it">info@liquidnation.it</a></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;">05/05/2023</span></p>
    <p><span style="font-family: Tahoma,Geneva, sans-serif;"><br></span></p>
    <p><br></p>
</div></template>