export default {
    bottombar: {
        a: 'RICARICA',
        b: 'COME FUNZIONA',
        c: 'ACCOUNT'
    },
    research: {
        research: 'Ricerca...'
    },
    install_app: {
        banner: 'Clicca per installare l\'app!',
        iosPopUp: {
            a: 'Per installare questa applicazione sul tuo dispositivo iOS:',
            b: 'Tocca il pulsante "Condividi" nella barra inferiore',
            c: 'Scegli "Aggiungi a Home Screen"'
        }
    },
    openPopup:{
        a:'Vai al luogo'
    },
    descPage: {
        cardA: {
            title: 'Gira',
            desc: 'la linguetta di 180°'
        },
        cardB: {
            title: 'Clicca sulla valvola',
            desc: 'e aspetta che la pressione eccessiva diminuisca'
        },
        cardC: {
            title: 'Ruota il tappo',
            desc: 'e gustati il tuo spritz!'
        }
    },
    accountPage: {
        loginPage: {
            desc: {
                a: 'Accedi',
            },
            buttons: {
                a: 'Accedi con Google',
                b: 'Accedi con Email',
                c: 'Registrati con Email'
            }
        },
        logInPage: {
            desc: {
                a: 'Accedi',
                b: 'Email',
                c: 'Inserisci il tuo indirizzo email',
                d: 'Password',
                e: 'Inserisci la tua password',
                f: 'Username o password errati'
            },
            buttons: {
                a: 'Accedi',
                b: 'Annulla',
                c: 'Reimposta la password'
            }
        },
        signUp: {
            desc: {
                a: 'Registrati',
                b: 'Nome utente',
                c: 'Inserisci il tuo nome utente',
                d: 'Accedi',
                e: 'Email',
                f: 'Inserisci il tuo indirizzo email',
                g: 'Password',
                h: 'Inserisci la tua password',
                i: 'Ripeti la password',
                j: 'Accetto di ricevere email di marketing.',
                k: 'Autorizzo l\'utilizzo dei miei dati personali in conformità al GDPR 679/16 "Regolamento europeo" e alla legge italiana 196/03.',
                l: 'Le password non corrispondono',
                m: 'Email già registrata'
            },
            button: {
                a: 'Accedi',
                b: 'Annulla',
            }
        },
        googleSignUp: {
            desc: {
                a: 'Vuoi ricevere email di marketing?',
                b: 'Accetto di ricevere email di marketing.',
                c: 'Autorizzo l\'utilizzo dei miei dati personali in conformità al GDPR 679/16 "Regolamento europeo" e alla legge italiana 196/03.'
            },
            button: {
                a: 'Continua',
                b: 'Annulla',
            }
        },
        main: {
            desc: {
                a: 'Paese',
                b: 'Desidero ricevere email di marketing e pubblicità'
            },
            buttons: {
                a: 'Modifica il profilo',
                b: 'Logout'
            }
        },
        edit: {
            desc: {
                a: 'Paese',
                b: 'Nome utente',
            },
            button: {
                a: 'Cambia password',
                b: 'Elimina account',
                c: 'Aggiorna',
                d: 'Annulla',
            }
        },
        resetPassword: {
            desc: {
                a: 'Reimposta la password',
                b: 'Email',
                c: 'Codice OTP',
                d: 'Le password non corrispondono',
                e: 'Codice OTP errato'
            },
            button: {
                a: 'Inviami il codice OTP',
                b: 'Reimposta la password',
                c: 'Annulla'
            }
        },
        delete: {
            desc: {
                a: 'Sei sicuro di voler eliminare il tuo account?',
                b: 'Tutti i datisaranno persi',
            },
            button: {
                a: 'Elimina',
                b: 'Annulla'
            }
        },
    },
    ageAllert: {
        desc: {
            aa: 'Attualmente ti trovi a',
            ab: ', per usare alcolici devi essere maggiorenne.'
        },
        button: {
            a: 'Confermo di essere maggiorenne'
        }
    },
    gpsPermission: {
        desc: {
            a: 'Abbiamo bisogno della tua posizione per trovare i punti di ricarica vicino a te!',
            b: 'Se il problema persiste:',
            c: 'Verifica di aver attivato il GPS',
            d: 'Verifica di aver autorizzato il browser ad accedere al GPS',
            e: 'Cambia browser'
        },
        button: {
            a: 'Richiedi autorizzazione'
        }
    }
}

