<template>
    <div v-if="!loggedIn">
        <div v-if="logInWith === 'email'" class="">
            <loginWithEmail class="" @userLogged="haveBeenLogged()" @close="logInWith = ''"/>
        </div>
        <div v-if="signUpWith === 'email'" class="">
            <signupWithEmail class="" @userLogged="haveBeenLogged()" @close="signUpWith = ''"/>
        </div>
        <div v-if="logInWith === 'google'">
            <loginWithGoogle @userLogged="haveBeenLogged()" @close="logInWith = ''" />
        </div>
        <div v-if="logInWith == '' && signUpWith == '' " class="m-2">
            <h2 class="text-2xl font-bold m-2">{{$t('accountPage.loginPage.desc.a')}}</h2>
            <div class="flex flex-col gap-4">
                <button class="py-2 px-4 rounded-md shadow-md bg-red-500 text-white text-center" @click="loginWithGoogle">
                    <div class="inline-flex items-center text-center">
                        <svg class="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                        {{$t('accountPage.loginPage.buttons.a')}}
                    </div>
                </button>
            </div>
            <div class="flex flex-col gap-4 my-2 items-center w-full">
                <div class="grid grid-cols-2 w-full gap-2">
                    <button class="py-2 px-4 rounded-md shadow-md logo-green-bg text-black " @click="loginWithEmail(1)">{{$t('accountPage.loginPage.buttons.b')}}</button>
                    <button class="py-2 px-4 rounded-md shadow-md border logo-green-border text-black " @click="signUpWithEmail">{{$t('accountPage.loginPage.buttons.c')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div v-if="loggedIn">
        <accountPage @logout="logOut()" />
    </div>
</template>

<script>
import loginWithEmail from './login_email.vue'
import signupWithEmail from './signup_email.vue'
import accountPage from './user_page.vue'
import loginWithGoogle from './loginWithGoogle.vue'

export default {
    name: 'userPage',
    data() {
        return {
            loggedIn: false,
            logInWith: '',  // se sta effettuando la regsitrazione tramite login, TODO:(si potrebbe eggiungere una key e trattare l'undefined invece di fare una variabile ma é da valutare)
            signUpWith: '',
        }
    },
    components: {
        loginWithEmail,
        signupWithEmail,
        accountPage,
        loginWithGoogle
    },
    mounted(){
        if(localStorage.getItem('sessionId')!=null){
            this.loggedIn = true
        }
    },
    methods: {
        loginWithGoogle() {
            this.logInWith = 'google'
            this.signUpWith = ''
        },
        loginWithEmail() {
            this.logInWith = 'email'  //step sono i passi per la registrazione, 1 = scehramta di login, 2=viene complatata la schermata di login
            this.signUpWith = ''
        },
        signUpWithGoogle() {
            this.signUpWith = 'google'
            this.logInWith = ''
            // Implement Google login logic here
            this.loggedIn = true
            this.username = 'John Doe'
            this.email = 'john.doe@example.com'
        },
        signUpWithEmail() {
            this.logInWith = ''
            this.signUpWith = 'email'  //step sono i passi per la registrazione, 1 = scehramta di login, 2=viene complatata la schermata di login
            // TODO: implementare richiesta se vuole registarsi alla mail newsletter, forse converrebbe prima farlo selezionare e poi inviare i dati al server, ma non é detto in quanto se si sta solo loggando e non sta facendo la registrazione questa fase non deve avvenire 
        },
        logOut() {
            this.loggedIn = false
        },
        haveBeenLogged() {
            this.loggedIn = true,
            this.logInWith = ''
            this.signUpWith = ''
        }
    },
}
</script>
  
<style>/* Use TailwindCSS classes here as needed */</style>