<template>
  <div class="top_notch_space"> <!-- spazio navbar -->
    <div class="">
      <div class="text-center place-content-center">
        <div v-if="!adultAgeConfirmed" class="fixed z-10 flex-col flex items-center justify-center w-full h-4/6 pr-4">
          <div class="fixed bg-white rounded-lg drop-shadow-xl m-8" >
            <ageAlertPopup class="w-fit" @ageConfirmed="ageConfirmed()" />
          </div>
        </div>
        <div v-if="!gpsPermissionAllowed" class="fixed z-10 flex-col flex items-center justify-center w-full h-4/6 pr-4">
          <div class="fixed bg-white rounded-lg drop-shadow-xl m-8" >
            <allowedGpsPopup class="w-fit" @gpsPermissionAllowed="requestGpsPermission()" />
          </div>
        </div>
        <div v-show="appCurrentPage == 'map'"> <!-- div mappa -->
          <div class="mb-2">
            <mapEngine class="h-4/6 z-0" @update:coords="updateCoords" @updateGpsStatus:state="updateGpsPermissionState" ref="gpsEngine" />
          </div>
          <div>
            <viewArticle/>
          </div>
          <div class="fixed bottom-0 w-full mb-20">
            <locationPopup v-if="selectedPoint != null" class="m-2" :selectedPoint="selectedPoint" :key="selectedPoint" @closePopUp="closePopUp()"/>
          </div>
        </div>
        <div v-show="appCurrentPage == 'info'" class="mb-20"> <!-- div mappa -->
          <descriptionPage/>
        </div>
        <div v-show="appCurrentPage == 'userProfile'"> <!-- div mappa -->
          <userPage/>
        </div>
      </div>
    </div>
  </div>
  <div class="fixed bottom-0 w-full">
    <bottomBar @change:page="setAppCurrentPage"/>
  </div>
  
</template>

<script>
import mapEngine from './map_engine.vue';
import locationPopup from './location_popup.vue';
import descriptionPage from './description_page/description_page.vue';
import bottomBar from './bottom_bar.vue'
import userPage from './account_page/account_page.vue'
import allowedGpsPopup from './allow_gps_popup.vue'
import ageAlertPopup from './age_alert_popup.vue'
import viewArticle from './view_article.vue'

export default {
  name: 'homePage',
  components: {
    mapEngine,
    locationPopup,
    descriptionPage,
    bottomBar,
    userPage,
    allowedGpsPopup,
    ageAlertPopup,
    viewArticle,
  },
  data() {
    return {
      selectedPoint:null,
      appCurrentPage: "map",  // dove si trova l'utente, map = mappa, info = info bicchiere
      gpsPermissionAllowed:true, //di default a true (per  non generare popup inutili) ma dopo controllo effettivamente
      adultAgeConfirmed:true,
    }
  },
  mounted(){
    //this.gpsRequestPermission()
    //this.hasGpsPermission()
    if (localStorage.getItem("ageIsConfirmed") === null) {  //se l'età non è stata confermata 
      this.adultAgeConfirmed=false
    }
  },
  methods:{
    updateCoords(point){
      this.selectedPoint = point
    },
    closePopUp(){
      this.selectedPoint = null
    },
    setAppCurrentPage(mode){
      this.appCurrentPage = mode
      this.selectedPoint = null
    },
    updateGpsPermissionState(state){
      this.gpsPermissionAllowed = state
    },
    requestGpsPermission(){
      //in riferimento al componente mapEngine con ref="gpsEngine", in modo da chiamare la funzione interna a quel componente
      this.$refs.gpsEngine.hasGpsPermission()
    },
    ageConfirmed(){
      this.adultAgeConfirmed=true
      localStorage.setItem("ageIsConfirmed", true)
    },
    openUrl(url){
      window.open(url, '_blank');
    }
  }
}
</script>

<style>
.top_notch{
  padding-top: calc(constant(safe-area-inset-top));
  padding-top: calc(env(safe-area-inset-top)) ; 
  background-color:black;
}
.top_notch_space{
  padding-top: calc(constant(safe-area-inset-top) + 2.8rem);
  padding-top: calc(env(safe-area-inset-top) + 2.8rem) ; 
  background-color:white;
}

</style>