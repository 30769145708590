import { createApp } from 'vue'
import App from './App.vue'
import Privacy from './components/privacyPolicy.vue'
import homePage from './components/home_page.vue';
import './style.css'
import './registerServiceWorker'
import axios from 'axios'
import vue3GoogleLogin from 'vue3-google-login'
import { pluginPwaInstallPrompt } from '@d-gs/vue-pwa-install-prompt'
import { createRouter, createWebHistory } from 'vue-router'
import i18n from './translations/translations'
import Google_oAuth_bridge from './components/account_page/GoogleoAuthNativeBridge.vue'

const routes = [
    {
        path: '/',
        name: 'GLASS REFILL',
        component: homePage
    },
    {
        path: '/privacy',
        name: 'Privacy Policies',
        component: Privacy
    },
    {
        path: '/google-oauth-bridge', //:tempid
        name: 'Log In Google',
        props: true,
        component: Google_oAuth_bridge
    }
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
})

let app = createApp(App)
app.use(i18n)
app.config.globalProperties.$axios = axios;
app.use(vue3GoogleLogin, {
    clientId: '63323597122-efpfmvlovrbqf3pq0lif6v3emocblp6q.apps.googleusercontent.com' //905305377103-ti5jcdhhl85a19cgl0ar7t55526pq07l.apps.googleusercontent.com
})
app.use(router) 
app.use(pluginPwaInstallPrompt)
app.mount('#app')

