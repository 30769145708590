<template>
    <div v-if="showResetPassword">
        <resetPassword @close="showResetPassword=false" />
    </div>
    <div v-if="showDeleteAccount">
        <div class="flex flex-col items-center bg-gray-50 p-4 justify-center rounded-lg ">
            <h1 class="text-4xl font-bold mb-4">{{$t('accountPage.delete.desc.a')}}</h1>
            <h1 class="text-sm font-bold mb-4">{{$t('accountPage.delete.desc.b')}}</h1>
            <div class="flex">
                <button class="px-4 py-2 bg-red-500 text-white font-bold rounded m-2" @click="deleteAccount()">{{$t('accountPage.delete.button.a')}}</button> 
                <button class="px-4 py-2 logo-green-bg text-white font-bold rounded m-2" @click="showDeleteAccount=false">{{$t('accountPage.delete.button.b')}}</button>
            </div>
        </div>
    </div>
    <div v-if="!showResetPassword && !showDeleteAccount">
        <div class="flex justify-center items-center w-full">
            <div class="px-4 pt-4 rounded-md bg-white w-full">
                <div class="items-center justify-center">
                    <img :src="userAvatar" class="w-24 h-24 rounded-full mx-auto mb-8">
                </div>
                <p class="text-sm text-gray-500">
                    <label class="block text-gray-700 font-bold mb-2 text-left" for="username">
                        {{$t('accountPage.edit.desc.b')}}
                    </label>
                    <input v-model="username"
                        class="text-sm outline-none bg-transparent text-black w-full border border-gray-300 rounded p-1"
                        type="text" placeholder="Username">
                </p>
                <p v-if="signupWith!='google'" class="text-sm text-gray-500 mt-2">
                    <label class="block text-gray-700 font-bold mb-2 text-left" for="username">
                        Email
                    </label>
                    <input v-model="email"
                        class="text-xs border border-gray-300 rounded outline-none bg-transparent w-full text-black p-1"
                        type="text" placeholder="Email">
                </p>
                <div class="flex items-center justify-between mt-5">
                    <div class="flex items-center ">
                        <p class="text-xs text-gray-500">{{$t('accountPage.edit.desc.a')}}:</p>
                        <select v-model="selectedCountry" class="text-xs text-gray-500 outline-none bg-transparent w-5/6"
                            @change="selectedCountryImage()">
                            <option v-for="country in countries" :key="country.code" :value="country.name">{{ country.name
                            }}
                            </option>
                        </select>
                    </div>
                    <img :src="countryImage" class="w-5 h-5 rounded-full mx-auto">
                </div>
                <button v-if="signupWith!='google'" class="py-2 px-4 rounded-md shadow-md border logo-green-border logo-green-text w-full mt-4"
                    @click="showResetPassword = true">{{$t('accountPage.edit.button.a')}}</button>
                <div v-if="signupWith!='google'"  class="mt-4 border bt-4"></div>
                <button class="py-2 px-4 rounded-md shadow-md border border-red-500 text-red-500 w-full mt-4"
                    @click="showDeleteAccount = true">{{$t('accountPage.edit.button.b')}}</button>
            </div>
        </div>
        <div class="absolute inset-x-0 bottom-16 p-2 pb-4">
            <button class="mt-2 py-2 px-4 rounded-md shadow-md logo-green-bg text-white w-full"
                @click="update">{{$t('accountPage.edit.button.c')}}</button>
            <button class="mt-2 py-2 px-4 rounded-md shadow-md bg-red-500 text-white w-full" @click="close">{{$t('accountPage.edit.button.d')}}</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import resetPassword from './resetPassword.vue';

export default {
    name: "updateUser",
    components: {
        resetPassword
    },
    data() {
        return {
            username: '',
            email: '',
            selectedCountry: '',
            signupWith:'',
            userAvatar: 'https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg',
            countryImage: '',
            countries: [],
            showResetPassword: false,
            showDeleteAccount: false
        }
    },
    methods: {
        selectedCountryImage() {
            this.$axios.get(`https://restcountries.com/v2/name/${this.selectedCountry}?fullText=true`)
                .then((response) => {
                    this.countryImage = response.data[0].flags.png
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        async getAllCountry() {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                let countries = response.data.map((country) => ({
                    code: country.cca2,
                    name: country.name.common,
                }));
                this.countries = countries.sort(function (a, b) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
            } catch (error) {
                console.error(error);
            }
        },
        setUserInfo(userInfo) {
            this.username = userInfo.username
            this.email = userInfo.email
            this.selectedCountry = userInfo.country
            this.userAvatar = userInfo.userAvatar
            this.signupWith = userInfo.signupWith
            this.selectedCountryImage()
        },
        update() {
            this.$axios.post(`${this.$root.apiDomain}/updateUser`,
                {
                    sessionId: localStorage.getItem('sessionId'),
                    email: this.email,
                    username: this.username,
                    country: this.selectedCountry,
                }).then(() => {
                    this.$emit("updated")
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        close() {
            this.$emit("close")
        },
        deleteAccount(){
            this.$axios.post(`${this.$root.apiDomain}/deleteAccount`,
                {
                    sessionId: localStorage.getItem('sessionId'),
                }).then(() => {
                    this.$emit("accountDeleted")
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    },
    created() {
        this.getAllCountry()
    },
};
</script>