<template>
<div></div>
</template>
<script>
import { googleTokenLogin } from "vue3-google-login";


export default {
    name: 'Google_oAuth_bridge',
    data() {
        return {
            googleToken: '',
        }
    },
    mounted() {
        this.loginWithGoogle()
    },
    methods: {
        async loginWithGoogle() {
            this.googleToken = await googleTokenLogin() 
            this.$axios
                .post(`${this.$root.apiDomain}/registerGoogleoAuthToken`,
                    {
                        tempid: this.$route.query.tempid,
                        google_access_token: this.googleToken.access_token,
                    })
                .then(async () => {
                    window.close();
                })
                .catch((error) => {
                    window.alert(`Error:${error}`)
                })
        },
    },
}
</script>
  
<style>
</style>