<template>
  <div class="ml-12 mt-2">
    <div class="relative">
      <div class=" flex justify-center items-center absolute top-0 right-0 w-full">
        <div class="m-2 mt-3 z-1 w-full">
          <div class="relative w-full">
            <input v-model="searchedPlace" @submit="searchPlace(true)" @input="searchPlace(false)"
              class="pl-2 pr-4 py-2 w-full border-gray-300 border-2 border-spacing-1 rounded-lg focus:outline-none focus:border-lime-500"
              type="text" :placeholder="$t('research.research')" />
            <button @click="searchPlace(true)"
              class="absolute inset-y-0 right-0 px-6 py-2 logo-green-bg text-white rounded-r-lg hover:bg-lime-600 transition duration-300">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg class="w-6 h-6 text-gray-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="placesFound != []" class="relative">
      <div v-if="placesFound != []" class="rounded-lg absolute top-0 right-0 mt-14 z-10 m-2 mr-12 w-full ml-12">
        <div v-for="place, index in placesFound" :key="place" class="bg-white p-2 rounded mx-2 ml-14">
          <p @click="setView(place)" class="w-full ">{{ place.display_name }}</p>
          <div v-if="index != placesFound.length - 1" class="border my-px border-gray-300"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="relative">
    <div ref="map" style="height: 500px;" class="z-0"></div>

    <button @click="centerGPS()"
      class="absolute bottom-0 right-0 m-2 p-2 border-gray-300 border-2 bg-white text-gray-600 rounded shadow-lg  hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-300">
      <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="2" x2="12" y2="6"></line>
        <line x1="12" y1="18" x2="12" y2="22"></line>
        <line x1="6" y1="12" x2="2" y2="12"></line>
        <line x1="18" y1="12" x2="22" y2="12"></line>
      </svg>
    </button>
  </div>
</template>

<script>
import L from 'leaflet';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import _ from 'lodash';
import positionIcon from '../assets/positionIcon.png';
import pointsIcon from '../assets/pointIcon.png';



export default {
  name: 'mapEngine',
  data() {
    return {
      debugInfo: { "isNativePlatform": Capacitor.isNativePlatform(), "platform": Capacitor.getPlatform() },
      actualPosition: null,
      oldPosition:null,
      mapCenter: null,
      map: null,
      gpsPermissionAllowed: false,
      markersInMap: [],
      positionStatisticsSended: false,  //true quando si manda al server la posizione anonima per statistiche 
      markers: [],
      gpsMarker: {main:null, precision:null},
      latLng: null,
      searchedPlace: '',
      placesFound: []
    };
  },
  mounted() {
    this.setMapHeight();
    this.initMap();
    this.initGps();
    this.updateRefillPoint();
    //this.updateMarker(this.markers);
  },
  methods: {
    initMap() {  // inizializzo la mappa
      // Crea la mappa
      this.map = L.map(this.$refs.map, { attributionControl: false }).setView([45.434405, 12.338698], 11);  //lascio o rimuovo gli attributi? per irmuoverli: { attributionControl:false }

      // Aggiunge la mappa di OpenStreetMap come layer
      //https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png 
      //https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png
      //https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png
      //https://tile-c.openstreetmap.fr/hot/{z}/{x}/{y}.png'
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ',
        maxZoom: 18,
      }).addTo(this.map);

      this.map.on('moveend', () => {
        this.updateRefillPoint()
      });
    },
    updateRefillPoint(){
      var mapBound = {
          "north": this.map.getBounds().getNorth(),
          "south": this.map.getBounds().getSouth(),
          "east": this.map.getBounds().getEast(),
          "west": this.map.getBounds().getWest(),
        }
        this.$axios
          .get(`${this.$root.apiDomain}/getRefillPoint?north=${mapBound.north}&south=${mapBound.south}&east=${mapBound.east}&west=${mapBound.west}`)
          .then((response) => {
            this.updateMarker(response.data)
          })
          .catch((error) => {
            console.log(error);

          })
    },
    initGps() {  // inizializzo gps
      let t = this;
      if (this.gpsPermissionAllowed) {
        Geolocation.getCurrentPosition().then(function (position) {
          console.log(position.accuracy)
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          t.mapCenter = { lat: lat, lng: lng, accuracy: position.coords.accuracy };
          t.actualPosition = { lat: lat, lng: lng , accuracy: position.coords.accuracy};
          t.updatePosition(true);
        });
      } else {
        t.hasGpsPermission();
      }

    Geolocation.watchPosition({
      timeout: 100,
      enableHighAccuracy: true,
      maximumAge: 0
    }, function (position, err) {
      if (!err) {
        console.log(position)
        if(t.actualPosition!=null){
          t.oldPosition = {lat: t.actualPosition.lat, lng:t.actualPosition.lng, accuracy:t.actualPosition.accuracy}  // non posso fare = sennò hanno lo stesso reference
        }
        t.actualPosition = { lat: position.coords.latitude, lng: position.coords.longitude , accuracy: position.coords.accuracy};
        if (t.mapCenter == null) {
          t.mapCenter = { lat: position.coords.latitude, lng: position.coords.longitude , accuracy: position.coords.accuracy};
          t.updatePosition(true);
        }
        if (t.gpsPermissionAllowed == false) {
          t.gpsPermissionAllowed = true;
          t.$emit('updateGpsStatus:state', true);
          t.updatePosition(true);
        }
        t.updatePosition();
      } else {
        console.log(err);
      }
    });
    },
    updateMarker(allMarker) {// aggiorna i marker dei posti 
      allMarker.forEach((marker) => {
        if (_.findIndex(this.markersInMap, marker) == -1) {  //uso _.findIndex di lodash in quanto confronta gli oggetti nell'array by value e non by reference
          const markerLayer = L.marker([marker.lat, marker.lng], {
            icon: L.icon({
              iconUrl: pointsIcon,
              iconSize: [30, 30],
            })
          }).addTo(this.map);
          this.markersInMap.push(marker)
          markerLayer.on('click', () => {
            this.openPoint(marker)
          });
        }
      });
    },
    movingAnimation(){
      if(this.oldPosition != null){
        let latMoving = (this.actualPosition.lat - this.oldPosition.lat)/100;
        let lngMoving = (this.actualPosition.lng - this.oldPosition.lng)/100;
        let accuracyMoving = (this.actualPosition.accuracy - this.oldPosition.accuracy)/100;
        let frameCount = 0;
        let actualLatLng = [this.oldPosition.lat, this.oldPosition.lng];  // variabile per la transizione
        let actualAccuracy = this.oldPosition.accuracy;  // variabile per la transizione
        let animation = setInterval(()=>{
          actualLatLng = [actualLatLng[0] + latMoving, actualLatLng[1] + lngMoving]
          actualAccuracy += accuracyMoving
          this.gpsMarker.main.setLatLng(actualLatLng)
          this.gpsMarker.precision.setLatLng(actualLatLng)
          this.gpsMarker.precision.setRadius(actualAccuracy/2 )
          frameCount ++
          if(frameCount==100){
            clearInterval(animation)
          }
        }, 1)
      }
    },
    updatePosition(setMapView = false) {  //aggiorna la posizione sulla mappa
      this.latLng = L.latLng(this.actualPosition.lat, this.actualPosition.lng);
      if (this.gpsMarker.main == null) {  //rimuovo il vecchio marker gps se è presente
        this.gpsMarker.main = L.marker(this.latLng, {  //posizione
          icon: L.icon({
            iconUrl: positionIcon,
            iconSize: [15, 15],
          })
        }).addTo(this.map).setZIndexOffset(1000);
        this.gpsMarker.precision = L.circle(this.latLng, this.actualPosition.accuracy/2 ,{  //precisione
          fillOpacity: 0.1,
          opacity: 0.3
        }).addTo(this.map)
      }else{
        this.movingAnimation()
      }
      if (setMapView) {
        this.map.setView(this.latLng, 11);
      }
      if (!this.positionStatisticsSended) {  //se non é stata mandata mando la posizione anonimamente al server per statistica
        this.positionStatisticsSended = true
        this.sendPositionAnonymus(this.actualPosition.lat, this.actualPosition.lng)
      }
    },
     async hasGpsPermission() {
      try {
        const result = await Geolocation.checkPermissions();
        const permissionState = result.location === 'granted';

        this.gpsPermissionAllowed = permissionState;
        this.$emit('updateGpsStatus:state', permissionState);
      } catch (error) {
        // Gestisci l'errore del permesso GPS non disponibile
      }
    },
    openPoint(marker) {
      this.$emit('update:coords', marker); //emetto un evento per cambiare la variabile nella home page per poi passarla all'altro componente
    },
    setMapHeight() {
      this.$refs.map.style = `height: ${(window.innerHeight-this.$root.topMargin) * (8 / 12)}px;`  //7/12
    },
    sendPositionAnonymus(lat, lng) {
      let getInfoFromCoords = () => {
        //prendo info paese dalla posizione lat e lng
        this.$axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&accept-language=en`)
          .then(response => {
            console.log(response);  //TODO: verificare anche con altri paesi
            var city = 'not available'
            if (response.data.address.city !== undefined) {
              city = response.data.address.city
            } else if (response.data.address.town !== undefined) {
              city = response.data.address.town
            }
            sendInfoToApi(city, response.data.address.country)
          })
          .catch(error => console.error(error));
      }

      let sendInfoToApi = (city, country) => {
        this.$axios.post(`${this.$root.apiDomain}/registerAnonymusLocationAccess`, {
          city: city,
          country: country
        })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      getInfoFromCoords()
    },
    searchPlace(set) {
      const searched = this.searchedPlace
      let t = this
      setTimeout(() => {
        if (searched == this.searchedPlace) {
          if (this.searchedPlace == '') {
            this.placesFound = []
            return
          }
          fetch(`https://nominatim.openstreetmap.org/search?q=${this.searchedPlace}&format=json`)
            .then(response => response.json())
            .then(data => {
              if (t.searchedPlace == '') {
                t.placesFound = []
                return
              }
              if (data.length === 0) {
                if (set) {
                  window.alert("Place not found");
                }
              } else {
                t.placesFound = []
                if (t.searchPlace != '') {
                  for (let index = 0; index < data.length; index++) {
                    if (data[index].display_name.length > 31) {
                      data[index].display_name = `${data[index].display_name.substring(0, 31)}...`
                    }
                    t.placesFound.push(data[index])
                    if (index == 4) { break }
                  }
                }
                if (set) {
                  t.setView(data[0])
                }
              }
            })
            .catch(error => { console.error(error); });
        }

      }, 500)
    },
    setView(data) {
      this.map.flyTo([data.lat, data.lon], 14)
      this.placesFound = []
    },
    centerGPS() {
      this.map.flyTo([this.actualPosition.lat, this.actualPosition.lng], 17, {
      animate: true,
      duration: 0.5
    });
    }
  }
};
</script>

<style>
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');

.z-1 {
  z-index: 1;
}
</style>