<template>
    <div class="p-2">
        <div v-if="showResetPassword">
            <resetPassword @close="showResetPassword = false" />
        </div>
        <div v-if="!showResetPassword">
            <div class="flex justify-center items-center w-full z-20">
                <form @submit.prevent class="bg-white rounded-lg w-full">
                    <h1 class="text-2xl font-bold mb-4">{{$t('accountPage.logInPage.desc.a')}}</h1>
                    <div>
                        <div class="mb-4">
                            <label class="block text-gray-700 font-bold mb-2" for="email">
                                {{$t('accountPage.logInPage.desc.b')}}
                            </label>
                            <input v-model="email"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="email" type="email" :placeholder="$t('accountPage.logInPage.desc.c')" required />
                        </div>
                        <div class="mb-4">
                            <label class="block text-gray-700 font-bold mb-2" for="password">
                                {{$t('accountPage.logInPage.desc.d')}}
                            </label>
                            <input v-model="password"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="password" type="password" :placeholder="$t('accountPage.logInPage.desc.e')" required />
                        </div>
                        <div v-if="loginError" class="bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 rounded w-full">{{$t('accountPage.logInPage.desc.f')}}</div>
                        <button @click="loginWithEmail()"
                            class="logo-green-bg hover:bg-lime-700 text-white font-bold py-2 px-4 my-2 rounded w-full">{{$t('accountPage.logInPage.buttons.a')}}</button>
                        <button @click="cancel()"
                            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full">{{$t('accountPage.logInPage.buttons.b')}}</button>
                    </div>
                </form>
            </div>
            <div>
                <button class="flex justify-center text-xs font-medium text-blue-500 my-2"
                    @click="showResetPassword = true"> {{$t('accountPage.logInPage.buttons.c')}} </button>
            </div>
        </div>

    </div>
</template>
<script>
import resetPassword from './resetPassword.vue';

const sha256 = require('js-sha256');

export default {
    name: 'loginWithEmail',
    components: {
        resetPassword
    },
    data() {
        return {
            loggedIn: false,
            showResetPassword: false,
            email: '',
            password: '',  //TODO: da trasformare in hash direttamente nel campo
            loginError:false,
        }
    },
    methods: {
        loginWithEmail() {
            let passwordHash = sha256(this.password);
            this.$axios
                .post(`${this.$root.apiDomain}/userAutentication`,
                    {
                        signinwith: "email",
                        email: this.email,
                        password_hash: passwordHash
                    })
                .then(async (response) => {
                    await localStorage.setItem('sessionId', response.data.id)
                    this.$emit('userLogged')
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response.status === 401){
                        this.loginError = true
                    }
                })
        },
        cancel() {
            this.$emit("close")
        }
    },
}
</script>
  
<style>
/* Use TailwindCSS classes here as needed */
</style>