<template>
    <div class="h-16 bg-gray-processed grid grid-cols-3 place-items-center">
        <div class="flex flex-col items-center justify-center" @click="changePage('map')">
            <!--no fill--><svg v-if="page!='map'" class="fill-white" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M444 446q-60 0-119 19.5T218 520l44 396h436l47-430h-48q-37.247 0-68.124-5.5Q598 475 544 460q-25-7-49.5-10.5T444 446Zm-233 7q51-32 111.5-49T444 386q30 0 59.5 4t56.5 12q51.128 14 78.226 19 27.097 5 57.774 5h56l21-190H187l24 217Zm51 523q-23.25 0-40.5-14.93Q204.25 946.141 202 923l-82-747h720l-82 747q-2.25 23.141-19.5 38.07Q721.25 976 698 976H262Zm182-60h253-435 182Z"/></svg>
            <!--fill--><svg v-if="page==='map'" class="fill-white"  xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M211 453q51-32 111.5-49T444 386q30 0 59.5 4t56.5 12q51 14 78 19t58 5h56l21-190H187l24 217Zm51 523q-23 0-40.5-15T202 923l-82-747h720l-82 747q-2 23-19.5 38T698 976H262Z"/></svg>
            <div class="text-center text-xs	no-select text-white">{{$t('bottombar.a')}}</div>
        </div>
        <div class="flex flex-col items-center justify-center" @click="changePage('info')">
            <svg v-if="page!='info'" class="fill-white" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M453 776h60V536h-60v240Zm26.982-314q14.018 0 23.518-9.2T513 430q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447 430q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.316q-54 54.316-127 86Q563 976 480.266 976Zm.234-60Q622 916 721 816.5t99-241Q820 434 721.188 335 622.375 236 480 236q-141 0-240.5 98.812Q140 433.625 140 576q0 141 99.5 240.5t241 99.5Zm-.5-340Z"/></svg>
            <svg v-if="page==='info'" class="fill-white" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M453 776h60V536h-60v240Zm26.982-314q14.018 0 23.518-9.2T513 430q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447 430q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.316q-54 54.316-127 86Q563 976 480.266 976Z"/></svg>
            <div class="text-center text-xs	no-select text-white">{{$t('bottombar.b')}}</div>
        </div>
        <div class="flex flex-col items-center justify-center" @click="changePage('userProfile')">
            <!--no fill--> <svg v-if="page!='userProfile'" class="fill-white" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M222 801q63-44 125-67.5T480 710q71 0 133.5 23.5T739 801q44-54 62.5-109T820 576q0-145-97.5-242.5T480 236q-145 0-242.5 97.5T140 576q0 61 19 116t63 109Zm257.814-195Q422 606 382.5 566.314q-39.5-39.686-39.5-97.5t39.686-97.314q39.686-39.5 97.5-39.5t97.314 39.686q39.5 39.686 39.5 97.5T577.314 566.5q-39.686 39.5-97.5 39.5Zm.654 370Q398 976 325 944.5q-73-31.5-127.5-86t-86-127.266Q80 658.468 80 575.734T111.5 420.5q31.5-72.5 86-127t127.266-86q72.766-31.5 155.5-31.5T635.5 207.5q72.5 31.5 127 86t86 127.032q31.5 72.532 31.5 155T848.5 731q-31.5 73-86 127.5t-127.032 86q-72.532 31.5-155 31.5ZM480 916q55 0 107.5-16T691 844q-51-36-104-55t-107-19q-54 0-107 19t-104 55q51 40 103.5 56T480 916Zm0-370q34 0 55.5-21.5T557 469q0-34-21.5-55.5T480 392q-34 0-55.5 21.5T403 469q0 34 21.5 55.5T480 546Zm0-77Zm0 374Z"/></svg>
            <!--fill--> <svg v-if="page==='userProfile'" class="fill-white" xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="25"><path d="M222 801q63-40 124.5-60.5T480 720q72 0 134 20.5T739 801q44-54 62.5-109T820 576q0-145-97.5-242.5T480 236q-145 0-242.5 97.5T140 576q0 61 19 116t63 109Zm257.814-195Q422 606 382.5 566.314q-39.5-39.686-39.5-97.5t39.686-97.314q39.686-39.5 97.5-39.5t97.314 39.686q39.5 39.686 39.5 97.5T577.314 566.5q-39.686 39.5-97.5 39.5Zm-.219 370q-83.146 0-156.275-31.5t-127.225-86Q142 804 111 731.159 80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.5q-54 54.5-127.129 86T479.595 976Z"/></svg>
            <div class="text-center text-xs no-select text-white">{{$t('bottombar.c')}} </div>
        </div>
    </div>
    <div v-if="bottomSpace" class="bg-gray-processed h-2">
    </div>
</template>
  
<script>
export default {
    name: 'bottomBar',
    data() {
        return {
            page:"map",
            bottomSpace:false
        }
    },
    mounted(){
        if(getComputedStyle(document.documentElement).getPropertyValue("--sab") != ' 0px'){
           this.bottomSpace = true 
        }
    }, 
    methods: {
        changePage(page){
            this.page = page
            this.$emit('change:page', page);  //emetto un evento per cambiare la variabile della pagina nella home page per cambiare quindi pagina
        }
    }
}
</script>
  
<style>
.no-select { 
	-webkit-touch-callout: none; 
	-webkit-user-select: none; 
	-khtml-user-select: none; 
	-moz-user-select: none; 
	-ms-user-select: none; 
	user-select: none; 
} 

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}
</style>