<template>
    <div class="flex flex-col items-center justify-center bg-white-500 p-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-32 w-32 text-white mb-4 logo-green-bg rounded-full object-center object-contain" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 1.667C6.29 1.667 3.333 4.623 3.333 8.333c0 1.733.645 3.314 1.704 4.519l3.54 4.047a1.667 1.667 0 002.512 0l3.54-4.047c1.059-1.205 1.704-2.786 1.704-4.519 0-3.71-2.957-6.666-6.667-6.666zm0 3.333a3.334 3.334 0 110 6.667 3.334 3.334 0 010-6.667z" clip-rule="evenodd" />
        </svg>
        <p class=" text-center mb-4 text-gray-900">{{$t('gpsPermission.desc.a')}}</p>
        <button @click="permissionAllowed()" class="text-gray-900 logo-green-bg py-2 px-4 rounded-lg hover:bg-lime-600 hover:text-white transition-colors duration-300">
            {{$t('gpsPermission.button.a')}}
        </button>
        <div v-if="!isFirstClick" class="m-8 flex flex-col items-center">
            <p class=" text-center mb-4 text-gray-900 font-bold">{{$t('gpsPermission.desc.b')}}</p>
            <div class="mx-6" >
                <ul class="list-disc">
                    <li class=" text-sm text-left mb-4 text-gray-900" >{{$t('gpsPermission.desc.c')}}</li>
                    <li class=" text-sm text-left mb-4 text-gray-900" >{{$t('gpsPermission.desc.d')}}</li>
                    <li class=" text-sm text-left mb-4 text-gray-900" >{{$t('gpsPermission.desc.e')}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'allowedGpsPopup',
    components: {
    },
    data() {
        return {
            isFirstClick:true
        };
    },
    methods:{
        permissionAllowed(){
            this.isFirstClick=false;
            this.$emit('gpsPermissionAllowed');
        }
    }
}
</script>